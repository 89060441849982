<!--
 * @Descripttion: 重置密码
 * @Author: zyr
 * @Date: 2021-05-27 23:25:38
 * @LastEditors: zyr
 * @LastEditTime: 2021-07-16 20:15:57
-->
<template>
  <div class="wrapper">
    <h2 class="header-tlt">信箱</h2>
    <h3 class="header-desc color-light">重置密码</h3>
    <van-form @submit="onSubmit" @failed="onFail" :show-error-message="false" validate-first>
      <div class="form-item hack-border-bottom">
        <div class="flex-grow password">
          <van-field v-model="password" placeholder="请输入新密码" name="pwd" maxlength="16" :error="false" autocomplete="off"
            :rules="[{
              message: '密码格式不正确',
              pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
            }]"
          />
        </div>
      </div>
      <div class="form-item hack-border-bottom form-item-last" style="margin-bottom: 0;">
        <div class="flex-grow password">
          <van-field v-model="newPasseord" placeholder="再次输入密码" name="confirmpwd" maxlength="16" :error="false" autocomplete="off"
            :rules="[{
              message: '密码格式不正确',
              pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/
            }]"
          />
        </div>
      </div>
      <p class="forget-password rule">6-16位密码，同时包含字母和数字，不区分大小写</p>
      <van-button :disabled="isDisable" :color="'#5460FE'" block native-type="submit"
        :style="isDisable ? {background: '#8F92BB', 'border-color': '#8F92BB'} : ''">确定</van-button>
    </van-form>
  </div>
</template>
<script>
import API from '@/assets/js/api'
const { resetPass } = API
export default {
  name: 'Reset',
  data () {
    return {
      password: '',
      newPasseord: ''
    }
  },
  computed: {
    isDisable: function () {
      if (this.password && this.newPasseord) return false
      else return true
    }
  },
  methods: {
    onFail (info) {
      const { errors: [{ message }] } = info
      this.$toast(message)
    },
    onSubmit (values) {
      if (this.password.toLowerCase() !== this.newPasseord.toLowerCase()) return this.$toast('密码输入不一致')
      this.$axios.post(resetPass, {
        ...values,
        ...this.$store.state.phoneInfo
      }).then(({ code, msg }) => {
        if (code === 0) {
          this.$toast({
            type: 'success',
            message: '重置成功',
            duration: 1500
          })
          setTimeout(() => {
            this.$router.go(-2)
          }, 1500)
        } else this.$toast(msg)
      })
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.password = ''
      vm.newPasseord = ''
    })
  }
}
</script>
<style  lang='less'>
@import '../../assets/less/flex.less';
.van-cell{
  padding: 0;
  font-size: .px2rem(32px)[@rem];
}
.van-field__control{
  color: #101112;
}
.van-button{
  height: .px2rem(108px)[@rem];
  border-radius: .px2rem(20px)[@rem];
  margin-top: .px2rem(194px)[@rem];
}
.van-button--disabled{
  opacity: 1;
}
.van-button__text{
  font-size: .px2rem(36px)[@rem];
}
</style>
<style lang='less' scoped>
@import '../../assets/less/flex.less';
@import '../../assets/less/common.less';
</style>
